import React, { useContext } from 'react';
import { string } from 'prop-types';
import { Arrows as OriginalArrows } from '@fiverr-private/carousel';
import getEventContext from '../helpers/getEventContext';
import { EventReportingManagerContext } from '../../../../components/EventReportingManager';
import { sendEvent } from '../../../../components/EventReportingManager/utils';

const Arrows = ({ eventName, reportingComponentName, eventEnrichment, ...restProps }) => {
    const componentName = reportingComponentName || 'Arrows';
    const { context: managerContext, enabled } = useContext(EventReportingManagerContext);
    const handleArrowClick = (direction) => {
        if (enabled && eventName) {
            const context = getEventContext({
                componentName,
                eventEnrichment: {
                    ...eventEnrichment,
                    'page.element.metadata': `arrow ${direction}`,
                },
                eventName,
            });

            sendEvent(eventName, 'click', context, managerContext);
        }
    };
    return <OriginalArrows onArrowClick={handleArrowClick} {...restProps}></OriginalArrows>;
};

Arrows.propTypes = {
    eventName: string,
    reportingComponentName: string,
    eventEnrichment: string,
};
export default Arrows;
